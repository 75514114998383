import {useQuery} from "react-query";
import axios from "axios";

export function useOfficeSections() {
  // Queries
  return useQuery('office-sections', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}/office-sections`)
    return response.data
  }, {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
}