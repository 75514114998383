import React from 'react';
import styles from "./rowcollapse.module.scss";

function RowCollapse({collapse = true, onClick = () => {}, cols = <></>, collapseContent = <></>}) {
  return (
    <div className={styles.tr}>
      <div onClick={(e) => onClick(e)}>
        {cols}
      </div>
      <div className={`w-full pb-10 ${collapse ? 'hidden' : 'block'}`}>
        {collapseContent}
      </div>
    </div>
  );
}

export default RowCollapse;