import React from 'react';
import ReactMarkdown from "react-markdown";
import style from "./collapseMore.module.scss";
function CollapseMore({content, className = ''}) {
  const [collapsed, setCollapsed] = React.useState(false)
  return (
    <>
      <div className={`flex items-center space-x-5 ${className} cursor-pointer`} onClick={() => {
        setCollapsed(!collapsed)
      }}>
        <span className={`text-3xl h-[30px] w-[10px] relative ${collapsed ? 'top-[-7.5px]' : 'top-[-5.3px]'}`}>{!collapsed ? '+' : '-'}</span>
        <div className={`border-t flex-1 pb-1`} style={{borderColor: '#707070'}}/>
      </div>

      <div className={style.collapseContainer} style={{height: `${collapsed ? 'auto' : 0}`,opacity: `${collapsed ? 1 : 0}`, visibility:`${collapsed ? 'visible' : 'hidden'}`}} >
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </>
  )
}

export default CollapseMore;