import React from 'react';
import styles from './header.module.scss';
import Logo from "./Logo";

function Header({}) {

  return (
    // <div className={styles.header}>
    //   <div className={'container mx-auto'}>
    //     <div className={styles.logo}>
    //       <h1>Buildings And Urban Solution</h1>
    //       <p>by gianclaudiocaponio</p>
    //     </div>
    //   </div>
    // </div>
    <div className={styles.headerFixed}>
      <div className={styles.containerHeader}>
        <Logo />
      </div>
    </div>
    // <div className={styles.containerHeader}>
    //   <div onClick={() => {
    //     navigate(`/home`)
    //   }} className={styles.logoFixed}>
    //     <h1>BAUS</h1>
    //     <p>by gianclaudiocaponio</p>
    //   </div>
    // </div>
  )
}

export default Header;