import React from 'react';
import styles from './nav.module.scss';
import {useTransitionNavigate} from "../../../hooks/common/common.hooks";

function Nav() {
  const navigate = useTransitionNavigate()

  return (
    <nav className={styles.navContainer}>
      <div className={styles.nav}>
        <ul className={styles.list}>
          <li>
            <a onClick={() => {
              navigate('/progetti')
            }}>PROJECTS</a>
          </li>
          <li>
            <a onClick={() => {
              navigate(`/office`)
            }}>OFFICE</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;