import React from 'react';
import styles from './slide.module.scss'

function Slide({image, title, caption, link }) {
  return (
    <div className={styles.slide} style={{backgroundImage: `url('${image}')`}} >
      <div className={styles.slideContent}>
        <div className={styles.title}>
          {title}
        </div>
      </div>
    </div>
  );
}

export default Slide;