import React from 'react';
import style from "./projectdetail.module.scss";
import {useIsVisible} from "../../../../hooks/common/common.hooks";
import CollapseMore from "../../../../components/CollapseMore/CollapseMore";
import ReactMarkdown from "react-markdown";


function ProjectImage({alt, src}) {
  const [imageVisible, imageRef] = useIsVisible()
  return <img
    ref={imageRef}
    alt={alt} src={`${process.env.REACT_APP_API_HOST}${src}`}
    className={style.imgProject + (imageVisible ? ' is-visible' : '')}
  />
}

function ProjectDetail(props
) {
  React.useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault()
    }

    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu)

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
    }
  }, [])
  const {project = null} = props
  return (
    !!project ? <div id="project" className={'container px-5 md:px-0 mx-auto pt-48'}>
        <div className="md:grid md:grid-cols-2 md:gap-10 md:mx-auto">
          <div className={'md:col-start-2 md:mx-auto mb-10 md:mb-0'}>
            <div className={`text-xs mb-5`}>{project.duration}</div>
            <div className={`text-3xl mb-5`}>{project.title}</div>
            <div className={`text-sm mb-10`} style={{lineHeight: '2rem', whiteSpace: "pre-wrap"}}>
              <ReactMarkdown children={project.small_description}/>
            </div>
            <CollapseMore content={project.description}/>
          </div>

          {
            project.gallery.map((x, i) => (
              <div key={x.url} className={(i + 1) % 3 === 1 ? 'md:col-span-2 relative md:mx-auto' : ''}>
                {i === 0 && <h2 className={style.titleCategory}>{project?.category?.slug}</h2>}
                <ProjectImage alt={x.alternativeText} src={x.url}/>
              </div>
            ))
          }
        </div>
      </div>
      : <></>
  );
}

export default ProjectDetail;