import React from 'react';
import style from './footer.module.scss'

function Footer(props) {
  return (
    <footer className={style.footer}>
      <div className={style.containerFooter}>
        <span>Copyright BAUS by gianclaudiocaponio - P.IVA: 06415800728</span>
      </div>
    </footer>
  );
}

export default Footer;