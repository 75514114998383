import {atom} from "recoil";

export const activeSlideIdAtom = atom({
  key: 'activeSlideIndex',
  default: 0,
});

export const lockScroll = atom({
  key: 'lockScroll',
  default: true,
});