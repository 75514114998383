import React, {useState} from 'react';
import {useTransitionCarousel} from "react-spring-carousel-js";
import styles from "./carousel.module.scss";
import Slide from "../../pages/Home/components/Slide/Slide";
import {activeSlideIdAtom, lockScroll} from "../../atoms/homeAtoms"
import {useRecoilState} from "recoil";

function Carousel(props) {
  const {items} = props;
  const [, setActiveSlide] = useRecoilState(activeSlideIdAtom)
  const [lockScrollState, setLockScroll] = useRecoilState(lockScroll)
  const [scrollDown, setScrollDown] = useState(false)
  const itemsSlider = items.map(project =>
    ({
      id: project.id,
      renderItem: <Slide image={`${process.env.REACT_APP_API_HOST}${project.highlight_image.url}`}
                         title={project.title}/>,
    })
  )

  React.useEffect(() => {
    if (items.length > 1) {
      const body = document.querySelector("body")
      body.className = lockScrollState ? 'overflow-hidden' : ''
    } else {
      const body = document.querySelector("body")
      body.className = ''
    }
  }, [lockScrollState, items])

  React.useEffect(() => {
    if (scrollDown) {
      const pr = document.querySelector("#project")
      pr.scrollIntoView({behavior: "smooth"})
      setScrollDown(false)
    }
  }, [scrollDown])

  const {
    carouselFragment,
    slideToItem,
    getIsActiveItem,
    getCurrentActiveItem,
    slideToNextItem,
    slideToPrevItem,
  } = useTransitionCarousel({
    withLoop: true,
    disableGestures: true,
    items: itemsSlider,
  })

  return (
    <div className={styles.carouselContainer}>
      {carouselFragment}
      <img alt={'caret'} onClick={() => {
        setLockScroll(false)
        setScrollDown(true)
      }} src='/caret_down.svg'
           className='cursor-pointer absolute mx-auto bottom-10 md:bottom-5 left-0 right-0 text-white z-50'/>

      {items.length > 1 && <>
        <img alt={'caret'} onClick={() => {
          const activeSlide = getCurrentActiveItem()
          setActiveSlide(activeSlide.index === 0 ? items.length - 1 : activeSlide.index - 1)
          slideToPrevItem()
        }} src='/caret_left.svg' className='cursor-pointer absolute left-4 text-white z-50'
             style={{top: '50%'}}/>

        <img onClick={() => {
          const activeSlide = getCurrentActiveItem()
          setActiveSlide(activeSlide.index === (items.length - 1) ? 0 : activeSlide.index + 1)
          slideToNextItem()
        }} alt={'caret'} src='/caret_right.svg' className='cursor-pointer absolute right-4 text-white z-50'
             style={{top: '50%'}}/>
      </>}


      {items.length > 1 && <div className={styles.sliderPagination}>
        {items.map((item, k) => {
          const isActiveItem = getIsActiveItem(item.id)
          return (
            <div key={item.id} className={`${isActiveItem ? styles.active : ''} ${styles.sliderPaginationItem}`}
                 onClick={() => {
                   slideToItem(k)
                   setActiveSlide(k)
                 }}/>
          )
        })}
      </div>}
    </div>
  );
}

export default Carousel;