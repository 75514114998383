import {useRecoilState} from "recoil";
import {globalLoadingAtoms} from "../../atoms/globalLoadingAtoms";
import {useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import axios from "axios";
import React from "react";
import {activeSlideIdAtom} from "../../atoms/homeAtoms";

export const useTransitionNavigate = () => {
  const [, setIsLoadingGlobal] = useRecoilState(globalLoadingAtoms)
  const [, setActiveSlide] = useRecoilState(activeSlideIdAtom)
  const location = window.location

  const navigate = useNavigate()
  return function (url) {
    if (url === location.pathname) return
    setIsLoadingGlobal(true)
    setActiveSlide(0)


    setTimeout(() => {
      navigate(url)
    }, 200)
  }
}


export const usePage = (pageId) => {
  return useQuery(pageId, async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}/${pageId}`)
    return response.data
  }, {
    refetchOnWindowFocus: false,
    enabled: !!pageId
  })
}


export const useIsVisible = () => {
  const domRef = React.useRef();
  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      setVisible(entries[0].isIntersecting);
      // No need to keep observing:
      //observer.unobserve(domRef.current);
    });

    observer.observe(domRef.current);

    return () => observer.disconnect();
  }, []);

  return [isVisible, domRef];
}