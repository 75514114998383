import React from 'react';
import styles from "./office.module.scss";
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import RowCollapseOffice from "../../components/RowCollapseOffice/RowCollapseOffice";
import {usePage} from "../../hooks/common/common.hooks";
import {useOfficeSections} from "../../hooks/office/office.hooks";
import ReactMarkdown from "react-markdown";
import ItemSection from "./components/ItemSection";
import Nav from "../../components/layout/Nav/Nav";
import {Helmet} from "react-helmet";

function Office() {
  const [collapse, setCollapse] = React.useState([true, true, true]);
  const {data = {}} = usePage('office')
  const {data: officeSections = []} = useOfficeSections()

  const toggleCollapse = (index) => {
    setCollapse(old => {
      const newToggles = [...old]
      newToggles[index] = !newToggles[index]
      return [...newToggles]
    })
  }
  return (
    <>
      <Helmet>
        <title>Office - BAUS by gianclaudiocaponio</title>
        <meta name="description" content="Our office - BAUS by gianclaudiocaponio"/>
      </Helmet>
      <Header/>
      <Nav/>
      <div className={styles.containerStudio}>

        <div className={styles.contentOffice}>
          <div><ReactMarkdown>{data.title}</ReactMarkdown></div>
          <div><ReactMarkdown>{data.content}</ReactMarkdown></div>
        </div>
      </div>
      <div className={'container px-5 md:px-0 mx-auto mt-20'}>
        <RowCollapseOffice
          collapse={collapse[2]} onClick={() => toggleCollapse(2)}
          cols={<div className={styles.rowData}>
            <div><h2>EXHIBITIONS</h2></div>
          </div>}
          collapseContent={<>{officeSections.filter(x => x.type === 'Exhibitions').map((archive, i) =>
            <ItemSection key={i} archive={archive} styles={styles}/>
          )}</>
          }/>

        <RowCollapseOffice
          collapse={collapse[1]} onClick={() => toggleCollapse(1)}
          cols={<div className={styles.rowData}>
            <div><h2>PUBLICATIONS</h2></div>
          </div>}
          collapseContent={<>{officeSections.filter(x => x.type === 'Publications').map((archive, i) =>
            <ItemSection key={i} archive={archive} styles={styles}/>
          )}</>
          }/>


        <RowCollapseOffice collapse={collapse[0]} onClick={() => toggleCollapse(0)}
                           cols={<div className={styles.rowData}>
                             <div><h2>AWARDS & RECOGNITIONS</h2></div>
                           </div>}
                           collapseContent={<>{officeSections.filter(x => x.type === 'Award').map((archive, i) =>
                             <ItemSection key={i} archive={archive} styles={styles}/>
                           )}</>
                           }/>
      </div>
      <div className={'container mx-auto mt-20 px-5 md:px-0'}>
        <div className={styles.contacts}>

          <p>
            <a
              target='_blank'
              href={'https://www.google.it/maps/place/The+Social+Hub+Florence+Lavagnini/@43.7823022,11.2508448,17z/data=!3m2!4b1!5s0x132a541e0cd2c321:0xc78dfac1f517d351!4m9!3m8!1s0x132a541e08073c43:0x67b49ce676d42454!5m2!4m1!1i2!8m2!3d43.7822984!4d11.2534197!16s%2Fg%2F11f15mw1_g?entry=ttu'}>
              C/O THE SOCIAL HUB <br/>
              Viale Spartaco Lavagnini 70-72<br/>
              50129 Firenze, Italy</a>
          </p>

          <p><a target='_blank' href={'mailto:studio@gianclaudiocaponio.com'}>studio@gianclaudiocaponio.com</a></p>
          <p><a target='_blank' href={'https://www.instagram.com/gianclaudiocaponio/?r=nametag'}>@gianclaudiocaponio</a>
          </p>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Office;