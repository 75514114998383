import React from "react";
import {Route, Routes, useLocation,} from "react-router-dom";
import Home from "./pages/Home/Home";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Progetti from "./pages/Progetti/Progetti";
import SingleProject from "./pages/SingleProject/SingleProject";
import Office from "./pages/Office/Office";
import {useRecoilState} from "recoil";
import {globalLoadingAtoms} from "./atoms/globalLoadingAtoms";
import {activeSlideIdAtom} from "./atoms/homeAtoms";

export default function Router() {
  const [isLoading, setIsLoading] = useRecoilState(globalLoadingAtoms)
  const [, setActiveSlide] = useRecoilState(activeSlideIdAtom)
  const location = useLocation()
  React.useEffect(() => {
    setActiveSlide(0)
    window.scrollTo(0, 0)
    setTimeout(() => {
      setIsLoading(false)
    }, 600)
  }, [location.pathname])

  return <>
    <div
      className={'w-full h-full fixed top-0 left-0 bg-white transition-opacity duration-200 z-50 ' + (isLoading ? 'opacity-100' : 'opacity-0 pointer-events-none')}
    />
    <Routes>
      <Route path="/comingsoon" element={<ComingSoon/>}/>
      <Route path="/" element={<Home/>}/>
      <Route path="/progetti" element={<Progetti/>}/>
      <Route path="/progetti/:projectId" element={<SingleProject/>}/>
      <Route path="/office" element={<Office/>}/>
    </Routes>
  </>
}
