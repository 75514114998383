import React from 'react';
import styles from "./comingsoon.module.scss";
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg';

function ComingSoon(props) {
  return (
    <div className={styles.comingsoon}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <h1>BAUS</h1>
          <p>by gianclaudiocaponio</p>
        </div>
        <div className={'md:flex items-end md:space-x-10'}>
          {/*<img className={styles.image} src={'/assets/images/gianclaudio-caponio.png'} alt="Gianclaudio Caponio"/>*/}
          <div className={'w-[300px]'}></div>
          <div className={`md:mt-0 mt-10`}>
            {/*<p className={'text-xl md:text-3xl'}>Gianclaudio Caponio</p>*/}
            <p className={'text-sm font-light mb-5'}>
              TSH Collab - Viale Spartaco Lavagnini 70-72<br/>
              50129 Firenze, Italy
            </p>
            <p className={'text-3xl md:text-6xl font-bold mb-3'} style={{lineHeight: '2.6rem'}}>
              contact<br/> us
            </p>
            <a href={'mailto:studio@gianclaudiocaponio.com'} className={'text-sm font-light'}>
              studio@gianclaudiocaponio.com
            </a>
            <a href={'https://instagram.com/gianclaudiocaponio?r=nametag'}>
              <InstagramIcon className={styles.instagram} />
            </a>

          </div>
        </div>

      </div>
    </div>
  );
}

export default ComingSoon;