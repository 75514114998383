import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import Router from "./Router";

const queryClient = new QueryClient(); // Instance of QueryClient

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
