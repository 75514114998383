import React, {useState} from 'react';
import styles from "./logo.module.scss";
import {useTransitionNavigate} from "../../../hooks/common/common.hooks";

function Logo(props) {
  const navigate = useTransitionNavigate()
  const [shrink, setShrink] = useState(false)

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setShrink(true)
      } else {
        setShrink(false)
      }
    })
    return () => window.removeEventListener('scroll')
  }, [])


  return (
    <>
      <div onClick={() => {
        navigate(`/`)
      }} className={`${styles.logo} ${shrink ? styles.shrinkLetters : null}`}>
        <h1>
          <strong className={'font-bold'}>B</strong>
          <span>u</span>
          <span>i</span>
          <span>l</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
          <span>s</span>
          <strong className={'font-bold'}>A</strong>
          <span>n</span>
          <span>d</span>
          <strong className={'font-bold'}>U</strong>
          <span>r</span>
          <span>b</span>
          <span>a</span>
          <span>n</span>
          <strong className={'font-bold'}>S</strong>
          <span>t</span>
          <span>r</span>
          <span>a</span>
          <span>t</span>
          <span>e</span>
          <span>g</span>
          <span>i</span>
          <span>e</span>
          <span>s</span>
        </h1>
        <p>by gianclaudiocaponio</p>
      </div>
    </>
  );
}

export default Logo;