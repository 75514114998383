import React from 'react';
import {useQuery} from "react-query";
import axios from "axios";

export function useEvidenceProjects(props) {
  // Queries
  return useQuery('evidenceProjects', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}/progettis?evidence_project=true&_limit=5&_sort=ordine:ASC`)
    return response.data
  }, {
    refetchOnMount: false
  })
}

export function useHomePageProjects(props) {
  // Queries
  return useQuery('homepageProjects', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}/progettis?homepage=true&_sort=ordine:DESC`)
    return response.data
  }, {
    refetchOnMount: false
  })
}


export function useProjects(props) {
  // Queries
  return useQuery('projects', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}/progettis?_sort=ordine:DESC`)
    return response.data
  }, {
    refetchOnMount: false
  })
}

export function useProject(id) {
  console.log('useProject', id)
  // Queries
  return useQuery('project', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_HOST}/progettis?_where[_or][0][id]=${id}&_where[_or][1][slug]=${id}`)
    return response.data
  }, {
    staleTime: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!id
  })
}