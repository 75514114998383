import React from 'react';
import Header from "../../components/layout/Header/Header";
import Carousel from "../../components/Carousel/Carousel";
import ProjectDetail from "./components/ProjectDetail/ProjectDetail";
import Footer from "../../components/layout/Footer/Footer";
import {useHomePageProjects} from "../../hooks/progetti/projects";
import {useRecoilState} from "recoil";
import {activeSlideIdAtom} from "../../atoms/homeAtoms";
import Nav from "../../components/layout/Nav/Nav";
import {Helmet} from "react-helmet";

function Home() {
  const {data, isLoading} = useHomePageProjects()
  const items = (data && !isLoading) ? [...data] : []
  const [activeSlideIndex, setActiveSlideIndex] = useRecoilState(activeSlideIdAtom)

  React.useEffect(() => {
    return () => {
      setActiveSlideIndex(null)
      const body = document.querySelector('body')
      body.className= ''
    };
  }, []);


  const project = items[activeSlideIndex]
  return (
    <>
      {isLoading ? <></> : <>
        <Helmet>
          <title>BAUS by gianclaudiocaponio</title>
          <meta name="description" content="BAUS by gianclaudiocaponio"/>
        </Helmet>
        <Header fixed={true}/>
        <Nav/>
        <Carousel items={items}/>
        <ProjectDetail project={project}/>
        <Footer/>
      </>}
    </>
  );
}

export default Home;