import React from 'react';
import ReactMarkdown from "react-markdown";

function ItemSection(props) {
  const [collapsed, setCollapsed] = React.useState(true)
  const {styles, archive} = props
  return (
    <div className={"py-3 border-b w-full"}>
      <div className={styles.rowDetailData}>
        {!archive?.highlight_image?.[0]?.url ? <div/> :
          <div><img alt={''} src={`${process.env.REACT_APP_API_HOST}${archive?.highlight_image?.[0]?.url}`}/></div>}
        <div>
          <span className={styles.rowDetailDataTitle} onClick={() => setCollapsed(old => !old)}>{archive.title}</span>
        </div>
        <div className={styles.rowDetailDataTitle}>{archive.data}</div>
      </div>
      {collapsed ? <></> : <div className={`text-sm my-3 ${styles.rowDescription}`}><ReactMarkdown>{archive.content}</ReactMarkdown></div>}
    </div>
  );
}

export default ItemSection;