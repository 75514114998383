import React from 'react';
import Header from "../../components/layout/Header/Header";
import Carousel from "../../components/Carousel/Carousel";
import ProjectDetail from "../Home/components/ProjectDetail/ProjectDetail";
import Footer from "../../components/layout/Footer/Footer";
import {useProject} from "../../hooks/progetti/projects";
import {useParams, } from "react-router-dom";
import Nav from "../../components/layout/Nav/Nav";
import {Helmet} from "react-helmet";

function SingleProject() {
  const params = useParams()
  const {data, isLoading} = useProject(params.projectId)
  const items = (data && !isLoading) ? [...data] : []

  const project = items[0]
  console.log(project)

  return (
    <>
      {(isLoading || !Array.isArray(items)) ? <></> : <>

        <Helmet>
          <title>{project.title} - BAUS by gianclaudiocaponio</title>
          <meta name="description" content={project.description}/>
        </Helmet>
        <Header fixed={true}/>
        <Nav/>
        <Carousel items={items}/>
        <ProjectDetail project={project}/>
        <Footer/>
      </>}
    </>
  );
}

export default SingleProject;