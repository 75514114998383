import React from 'react';
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import styles from "./progetti.module.scss";
import RowCollapse from "../../components/RowCollapse/RowCollapse";
import {useProjects} from "../../hooks/progetti/projects";
import {useTransitionNavigate} from "../../hooks/common/common.hooks";
import Nav from "../../components/layout/Nav/Nav";
import ReactMarkdown from "react-markdown";
import {Helmet} from "react-helmet";


function Progetti() {
  const [collapse, setCollapse] = React.useState([]);
  const {data, isLoading} = useProjects()
  const items = (data && !isLoading) ? [...data] : []
  const navigate = useTransitionNavigate()

  const renderRows = () => {
    return items.map((project, i) => {

        const row = <div
          className={`text-xs md:grid md:grid-cols-12 md:gap-4 px-5 md:px-0 ${styles.rowData} ${styles.tr}`}>
          <div className={`col-span-1`}>{project.duration}</div>
          <div className={`col-span-5`}>{project.title}</div>
          <div className={`col-span-2`}>{project?.category?.slug}</div>
          <div className={`col-span-2`}>{project?.location}</div>
          <div className={`col-span-2`}>{project.status}</div>
          {/*<div className={``}>{*/}
          {/*  project.evidence_project*/}
          {/*    ? ''*/}
          {/*    : project.small_description*/}
          {/*      ? collapse[i]*/}
          {/*        ? '-'*/}
          {/*        : '+'*/}
          {/*      : ''}</div>*/}
        </div>

        if (!project.evidence_project && !project.small_description) return row

        return <RowCollapse
          collapse={!!!collapse[i]}
          onClick={project.evidence_project
            ? () => {
              navigate(`/progetti/${project.slug}`)
            }
            : () => setCollapse(old => {
              const newColl = [...old]
              newColl[i] = !newColl[i]
              return newColl
            })}
          collapseContent={
            <div className={`text-sm mt-5 container px-5 md:px-0 mx-auto`} style={{lineHeight: '1.5rem', whiteSpace: "pre-wrap"}}>
              <ReactMarkdown>{project.small_description}</ReactMarkdown></div>}
          cols={row}
        />
      }
    )
  }

  return (
    <>
      <Helmet>
        <title>Projects - BAUS by gianclaudiocaponio</title>
        <meta name="description" content="Our projects - BAUS by gianclaudiocaponio"/>
      </Helmet>
      <Header/>
      <Nav/>
      <div className="container mx-auto">
        <div className={'mt-14 md:mt-32 mb-10'}>
          <div className={'hidden text-xs md:grid md:grid-cols-12 gap-4'}>
            <div className={'col-span-1'}>year</div>
            <div className={'col-span-5'}>project</div>
            <div className={'col-span-2'}>program</div>
            <div className={'col-span-2'}>location</div>
            <div className={'col-span-2'}>status</div>
            {/*<div/>*/}
          </div>

          {renderRows()}


        </div>

      </div>
      <Footer/>
    </>
  );
}

export default Progetti;